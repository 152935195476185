

import { Component, Vue } from 'vue-property-decorator'
import ImportData from '@/components/importTemplateData/Index.vue'
import { KeyObject, DeviceDetail, DeviceList, DeviceTypeList } from '@/types/devices'
import { SupplierList } from '@/types/supplier'
import { PageType } from '@/types/customer'
import { ElForm } from 'element-ui/types/form'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'DeviceList',
  components: { ImportData }
})
@KeepAlive
export default class extends Vue {
  private deviceTypeList: object[] = []
  private customerList: object[] = []
  private supplierList: object[] = []
  private agentList: Array<{ agentId: string; agentName: string }> = []
  private deviceStatusList: KeyObject[] = [{
    key: '1',
    value: '正常'
  }, {
    key: '2',
    value: '故障'
  }]

  private AllocateStatus: KeyObject[] = [{
    key: '0',
    value: '未分配'
  }, {
    key: '1',
    value: '已分配'
  }, {
    key: '2',
    value: '已分配'
  }]

  private AllocateStatus2: KeyObject[] = [{
    key: '0',
    value: '未分配'
  }, {
    key: '1',
    value: '已分配'
  }]

  private ifVirtualStatus: KeyObject[] = [{
    key: '0',
    value: '真实设备'
  }, {
    key: '1',
    value: '虚拟设备'
  }]

  private searchInfo: object = {
    deviceTypeId: '',
    deviceCode: '',
    deviceNumber: '',
    customerId: '',
    supplierId: '',
    deviceStatus: '',
    isAllocated: '',
    ifVirtual: ''
  }

  private total = 0
  private page = 1
  private size = 10
  private loading = false
  private isShowImport = false
  private tableData: DeviceDetail[] = []
  private selectInfo: DeviceDetail[] = []
  private showAllocateDialog = false
  private allocateLoading = false
  private isDeviceName = false
  private allocateInfo: {devices: string[]; customerId: string} = {
    devices: [],
    customerId: ''
  }

  private allocateRules = {
    customerId: [
      { required: true, message: '请选择客户', trigger: 'change' }
    ]
  }

  private deviceInfo = {
    deviceId: '',
    deviceName: ''
  }

  created (): void {
    this.getDeviceTypeList()
    this.getSupplierList()
    this.getCustomerList()
    this.getAgentList()
    this.getList()
  }

  // 设备状态展示
  showDeviceStatus (status: string): string {
    const _ind = this.deviceStatusList.findIndex(item => item.key === status)
    return _ind !== -1 ? this.deviceStatusList[_ind].value : ''
  }

  // 分配状态展示
  showAllocateStatus (status: string): string {
    const _ind = this.AllocateStatus.findIndex(item => item.key === status)
    return _ind !== -1 ? this.AllocateStatus[_ind].value : ''
  }

  // 真假设备展示
  showIfVirtualStatus (status: string): string {
    const _ind = this.ifVirtualStatus.findIndex(item => item.key === status)
    return _ind !== -1 ? this.ifVirtualStatus[_ind].value : ''
  }

  // 流量卡
  //  0 未绑定 1 正常 2 即将到期 3 已到期
  getCardType (key: number) {
    const obj: {[key: number]: {text: string; className: string}} = {
      0: {
        text: '未绑定',
        className: 'colorBlack'
      },
      1: {
        text: '正常',
        className: 'colorGreen'
      },
      2: {
        text: '即将到期',
        className: 'colorOrange'
      },
      3: {
        text: '已到期',
        className: 'colorRed'
      }
    }

    return obj[key] ? obj[key] : {
      text: '',
      className: ''
    }
  }

  // 搜索
  onSearch (): void {
    this.page = 1
    this.getList()
  }

  getDeviceTypeList () {
    this.$axios.get<DeviceTypeList>(this.$apis.devices.getDeviceTypeList).then(res => {
      this.deviceTypeList = res.list
    })
  }

  getSupplierList () {
    this.$axios.get<SupplierList>(this.$apis.supplier.list).then(res => {
      this.supplierList = res.list
    })
  }

  getCustomerList () {
    this.$axios.get<{total: number; list: PageType[]}>(this.$apis.customer.customerList).then(res => {
      this.customerList = res.list
    })
  }

  // 获取代理商列表
  getAgentList () {
    this.$axios.get(this.$apis.agent.selectAgentByList, {}).then((res) => {
      this.agentList = res.list || []
    })
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<DeviceList>(this.$apis.devices.selectDeviceByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total
      this.tableData = res.list
    }).finally(() => {
      this.selectInfo = []
      this.loading = false
    })
  }

  // 新增设备
  addDevice (): void {
    this.$router.push({ name: 'deviceAdd' })
  }

  // 选择行
  handleSelectionChange (val: DeviceDetail[]): void {
    this.selectInfo = val
  }

  // 批量分配
  batchAllocate (): void {
    if (this.selectInfo.length > 0) {
      this.showAllocateDialog = true
      this.allocateInfo.devices = []
      this.selectInfo.forEach(item => {
        this.allocateInfo.devices.push(item.deviceId)
      })
    } else {
      this.$message.error('请选择要分配的设备')
    }
  }

  // 垃圾站
  garbageStation (): void {
    this.$router.push({ name: 'garbageStation' })
  }

  // 分配
  allocateCustomer (row: DeviceDetail): void {
    this.showAllocateDialog = true
    this.allocateInfo.devices = [row.deviceId]
  }

  // 分配数据校验
  allocateDevice (formName: string): void {
    (this.$refs[formName] as ElForm).validate(valid => {
      if (valid) {
        this.doAllocateDevice(formName)
      }
    })
  }

  // 分配执行
  doAllocateDevice (formName: string): void {
    this.allocateLoading = true
    this.$axios.post(this.$apis.devices.batchAllocateDevice, this.allocateInfo).then((res) => {
      this.$message.success('分配成功')
      this.getList()
    }).finally(() => {
      (this.$refs[formName] as ElForm).resetFields()
      this.allocateLoading = false
      this.showAllocateDialog = false
    })
  }

  // 回收
  recoverDevice (row: DeviceDetail): void {
    this.$confirm('是否回收此设备?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.doRecoverDevice(row)
    })
  }

  // 执行回收
  doRecoverDevice (row: DeviceDetail): void {
    this.$axios.post(this.$apis.devices.batchRecycle, { devices: [row.deviceId] }).then(() => {
      this.$message.success('操作成功')
      this.getList()
    })
  }

  // 判断设备是否能分配
  selectable (row: {isAllocated: string}) {
    if (row.isAllocated === '0') {
      return true// 非禁用状态
    } else {
      return false// 禁用状态
    }
  }

  // 设备名称修改
  deviceNameChange (info: { deviceId: string; deviceName: string }) {
    this.deviceInfo = {
      deviceId: info.deviceId,
      deviceName: info.deviceName
    }
    this.isDeviceName = true
  }

  // 确认修改名称
  submitDevice () {
    this.$axios.post(this.$apis.devices.updateDeviceName, this.deviceInfo).then(() => {
      this.$message.success('设备名称修改成功!')
      this.resetDevice()
      this.getList()
    })
  }

  // 取消设备名称修改
  resetDevice () {
    this.isDeviceName = false
    this.deviceInfo = {
      deviceId: '',
      deviceName: ''
    }
  }

  // 删除设备(移入垃圾站)
  deleteDevice (row: { deviceId: string}) {
    this.$confirm('确认删除该设备吗？', '提示').then(() => {
      this.$axios.post(this.$apis.devices.deleteDevice, {
        deviceId: row.deviceId
      }).then(() => {
        this.$message.success('设备删除成功!')
        this.getList()
      })
    })
  }
}
